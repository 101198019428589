
import { defineComponent, onMounted, ref, watch } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleBrCellPhoneNumber, campHandleCPF, campHandleEmailVerification, campHandleEveryoneIsTrue, handleCreditCardNumber } from '@/composables/DataValidation';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useAuthStore } from '@/store/AuthStore';
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

interface IUserData {
  cpf: string,
  name: string,
  email: string,
  tel: string,
  moderator: boolean,
  consultant: boolean,
  birth_date: string,
  card_number: string | null,
  card_pin: string | null,
  idStore: [],
}

interface IUserResponse {
  data: {
    error: boolean,
    message: string,
    data: IUserData
    errorCode: string
  },
  status: number
}

export default defineComponent({
  name: "UserPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn
  },
  setup() {
    const authStore = useAuthStore()
    const initData: IUserData = {
      cpf: "",
      name: "",
      email: "",
      tel: "",
      moderator: false,
      consultant: false,
      birth_date: "",
      card_number: null,
      card_pin: null,
      idStore: [],
    }
    const filterBaseV2Store = useFilterBaseV2Store()
    const storeOptions: any = ref([])
    const postObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data: null | IUserData
    }>({
      isLoading: false,
      noError: true,
      data: { ...initData }
    })

    /** Warning of unfilled fields */
    const isRequiredField = ref(false)
    const isRequiredNameComplete = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{ 
      cpf: boolean,
      mail: boolean,
      phoneNumber: boolean,
      card: boolean,
      pin: boolean,
      storeSelected: boolean,
     }>({
      cpf: false,
      mail: false,
      phoneNumber: false,
      card: false,
      pin: false,
      storeSelected: false
    })
   const loaderStore = useLoaderStore();

    /** Validate CPF */
    watch(() => postObject.value.data?.cpf, () => {
      if(postObject.value.data?.cpf) {
        isInvalidField.value.cpf = !campHandleCPF(postObject.value.data.cpf)
      } else isInvalidField.value.cpf = false
    })

    /** Validate email */
    // watch(() => postObject.value.data?.email, () => {
    //   if(postObject.value.data?.email) {
    //     isInvalidField.value.mail = !campHandleEmailVerification(postObject.value.data.email)
    //   } else isInvalidField.value.mail = false
    // })

    /** Validate phone number */
    watch(() => postObject.value.data?.tel, () => {
      if(postObject.value.data?.tel) {
        isInvalidField.value.phoneNumber = !campHandleBrCellPhoneNumber(postObject.value.data.tel)
      } else isInvalidField.value.phoneNumber = false
    })

    watch(() => postObject.value.data?.card_number, () => {
      if(postObject.value.data?.card_number) {
        isInvalidField.value.card = !handleCreditCardNumber(postObject.value.data.card_number)
      } else isInvalidField.value.card = false
    })

    watch(() => postObject.value.data?.card_pin, () => {
      if(postObject.value.data?.card_pin) {
        isInvalidField.value.pin = postObject.value.data.card_pin.length !== 19
      } else isInvalidField.value.pin = false
    })

    /** Reset warning */
    watch(() => postObject.value.data, () => {
      if(isRequiredField.value)
        isRequiredField.value = false
      if(isInvalidFieldWarning.value)
        isInvalidFieldWarning.value = false
    },
    { deep: true })
    
    const { showTimeAlert } = useAlert()

    async function onSubmitForm() {
      const { data } = postObject.value
      if(!data) {
        return showTimeAlert("Algo deu errado!", "error")
      }
      if(
        !data.cpf.length ||
        !data.name.length ||
        !data.tel.length ||
        // !data.email.length ||
        !data.birth_date?.length ||
        (data.card_number && !data.card_pin?.length) ||
        !data.idStore.length
      ) {
        return isRequiredField.value = true
      }
      if(!data.name.includes(' ')) return isRequiredNameComplete.value = true
      if(campHandleEveryoneIsTrue(isInvalidField.value)) {
        isInvalidFieldWarning.value = true
        return isRequiredField.value = true
      }
      loaderStore.open()
      try {
        const { data } = postObject.value
        if(data) {
          const tel = campHandleBrCellPhoneNumber(data.tel, "unformatted")
          const cpf = campHandleCPF(data.cpf, "unformatted")
          const card_number_check = data.card_number ? handleCreditCardNumber(data.card_number, "unformatted") : null
          const card_number = typeof card_number_check == "string" ? card_number_check : null
          if(typeof tel === "boolean" || typeof cpf === "boolean") {
            loaderStore.close()
            return showTimeAlert("Algo deu errado!", "error")
          }
          const card_pin = (typeof card_number === "string" && card_number.length > 0) ? data.card_pin : null
          const postData: IUserData = {
            ...data,
            tel,
            cpf,
            card_number,
            card_pin,
          }
          await axios.post(`/api/postCompetitor`, postData)
          showTimeAlert("Registro criado com sucesso!")
          postObject.value.data = { ...initData }
          return router.push("/vendedores")
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    async function getStores() {
      try {
        const company = await filterBaseV2Store.getCompanyInSession()
        if(!company)
          return showTimeAlert("Necessário estar setado em uma Companhia!", "error")
        const response = await axios.get(`/api/getStore?id_company=${ company.id }`)
        storeOptions.value = response.data.data
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally { }
    }

    onMounted(async() => {
      await getStores()
    })

    return {
      postObject,
      isRequiredField,
      isInvalidFieldWarning,
      isInvalidField,
      onSubmitForm,
      authStore,
      storeOptions,
      isRequiredNameComplete
    }
  }
})
